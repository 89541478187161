import React from "react";
import { getItemBySlug } from "./CmsUtility";
class CmsButton extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }

  formatLink() {
    const { cms_section, slug, field } = this.props
    const cmsItem = getItemBySlug(cms_section, slug);
    const html = cmsItem.data[field];
    
    let formattedHtml = html.replaceAll("&amp;nbsp;", "&nbsp;")
    return formattedHtml
  }

  render() {
    const { cms_section, slug, field, button_text } = this.props
    if (cms_section === null || cms_section === undefined) return <></>;
    return (
      <a href={this.formatLink()} target="_blank">
        <button className="sg-button-secondary sg-button-large">
          {button_text}
        </button>
      </a>
    )
  }
}

export default CmsButton;
