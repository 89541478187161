import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
import Select from 'react-select'
class RegistrationEmailValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      error: null,
      emailValue: "",
      showDecline: false,
      showRegistered: false,
      showNotFound: false,
      showRsvpPassed: false
    }
    this.verifyEmail = this.verifyEmail.bind(this);
  }


  verifyEmail(e) {
    e.preventDefault()
    const { features } = this.props;
    const { emailValue, selectedDate} = this.state;
    if(features &&  features.pick_a_date) {
      if(selectedDate == null) {
        this.setState({
          error: "Please select a program to register for."
        })
        return;
      }
    }
    fetchAPI(`/registration/verify_email`,(json)=>{
      let error = null;
      let attendee = null;
      let declined = null;
      let registered = null;
      let passedRSVP = null;
      let notFound = null;
      if(json){
        attendee = json.attendee;
        error = json.error;
        declined = json.declined;
        registered = json.registered;
        passedRSVP = json.passed_rsvp;
        notFound = json.not_found;
      }else {

      }

      if( error ) {
        console.log({error})
        if (declined) {
          this.setState({
            showDecline: true,
            showRegistered: false,
            showNotFound: false,
            showRsvpPassed: false,
            error: error
          });
        } else if (registered) {
          this.setState({
            showDecline: false,
            showRegistered: true,
            showNotFound: false,
            showRsvpPassed: false,
            error: error
          });
        } else if (notFound) {
          this.setState({
            showDecline: false,
            showRegistered: false,
            showNotFound: true,
            showRsvpPassed: false,
            error: error
          })
        } else if (passedRSVP) {
          this.setState({
            showDecline: false,
            showRegistered: false,
            showNotFound: false,
            showRsvpPassed: true,
            error: error
          })
        } else {
          this.setState({
            error: error
          });
        }

      } else if (!error && attendee) {
        window.location = `/registration?id=${attendee}${features.pick_a_date ? "&ticket="+selectedDate.value:""}${features.sandbox ? "&sandbox=true":""}`
      }else {

      }
    } ,{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }

  renderPickADate() {
    const { features } = this.props;
    const { selectedDate } = this.state;
    const options = [
      {value: 'day-5', label: 'Sunday, February 6, 2022'}
    ]
    if(features && features.pick_a_date) {
      return (
        <>
          <label>Please select the day you would like to join us from the dropdown below.</label>
          <div style={{maxWidth:"600px",margin: "auto"}}>
            <Select
              options={options}
              classNamePrefix='registration-select'
              value={selectedDate}
              onChange={(option) => {
                this.setState({
                  selectedDate: option
                })
              }}
            />
          </div>
          <br/>
        </>
      )
    }
  }

  render() {
    const { overnight } = this.props;
    const { emailValue, error, showDecline, showRegistered, showNotFound, showRsvpPassed } = this.state;
    return (
      <div className="row">
        <form onSubmit={this.verifyEmail} className="att-proam-registration-email-validation registration-form ">
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '60px'}}>
              <h5>We look forward to welcoming you to the 2025 Masters Tournament in Augusta, GA.</h5>
            </div>
            <div className="att-proam-registration-email-validation-copy" style={{marginTop: '24px'}}>
              <h6>As this is an invitation only event, please ensure the email address entered below is that of the registrant.</h6>
            </div>
            <div className="att-proam-registration-email-validation-fields">
              <br/>
              <div className="label-wrapper">
                <label className="label-english">Enter Email Address *</label>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
            />
            <br/>
            {this.renderPickADate()}
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12 col-md-6" style={{textAlign: "left"}}>
            {
              showDecline ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                Our records indicate you previously declined to attend. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20Masters%20Tournament">executiverelations@cisco.com</a> if you have any questions.
              </div>
              :
              ""
            }
            {
              showRegistered ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                Our records indicate you previously registered. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20Masters%20Tournament">executiverelations@cisco.com</a> for assistance. Thank you for your interest in attending the 2025 Masters Tournament.
              </div>
              :
              ""
            }
            {
              showNotFound ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                Thank you for your interest in attending the 2025 Masters Tournament. As this is an invitation only event, please ensure the email address used matches the invitation. Please contact <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%202025%20Masters%20Tournament">executiverelations@cisco.com</a> for assistance.
              </div>
              :
              ""
            }
            {
              showRsvpPassed ?
              <div className="att-proam-registration-email-validation-error body2" style={{padding: "0px 0px"}}>
                Thank you for your interest in attending the 2025 Masters Tournament. Our records indicate your invitation has expired.
              </div>
              :
              ""
            }
          </div>
          <div className="col-xs-12">
            <div className="att-proam-registration-email-validation-submit">
              <br/>
              <button
                type="button"
                onClick={this.verifyEmail}
                className="sg-button sg-button-secondary"
              >
                Continue
              </button>
              <br />
              <br />
              <br />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegistrationEmailValidation;
