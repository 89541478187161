import React, { useState } from "react";
const LandingPageHeaderNavMobile = ({header_sections, include_images}) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderMenu = () => {
    return (
      <div className="landing-page-header-mobile-menu">
        {isOpen ? (
          renderMenuInner()
        ):""}
      </div>
    )
  }

  const renderMenuInner = () => {
    const renderedMenuInner = header_sections.map(item =>
      <a href={`#${item.id}`}>
        <div class="landing-page-header-nav-item" onClick={() => setIsOpen(!isOpen)} key={`header-item-mobile-${item.id}`}>
          {
            include_images ?
            <img src={`/images/landing/ico-${item.id}.svg`} />
            :
            <></>
          }
          <div className="body1">{item.mobile_title}</div>
        </div>
      </a>

    );
    return (
      <div class="landing-page-header-nav-wrapper">
        {renderedMenuInner}
      </div>
    );
  }

  const getOpenImage = () => {
    return 'ico-menuopen.svg';
  }
  const getCloseImage = () => {
    return 'ico-menuclose.svg';
  }

  const renderDropdownIcon = () => {
    return (
      <img
        onClick={() => setIsOpen(!isOpen)}
        src={`/images/landing/${isOpen ? getCloseImage() : getOpenImage()}`}
        className={`sg-landing-page-mobile-menu-dropdown-icon ${isOpen ? "open" : "closed"}`}
        alt=""
      />
    )
  }

  return (
    <>
      <div className="navbar-mobile-landing-page-addition">
        <div className="container sg-header-container"></div>
        {renderDropdownIcon()}
        {renderMenu()}
      </div>
    </>
  );
};

export default LandingPageHeaderNavMobile;
